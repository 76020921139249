import "bootstrap/dist/js/bootstrap.min.js";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { Card } from "../Home/Home";

export default function ProductList() {
  const [cars, setCars] = useState([]);
  useEffect(() => {
    axios.get("/cars.json").then((res) => {
      setCars(res.data);
    });
  }, []);
  return (
    <div className="App-products">
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
          padding: "10px",
          boxSizing: "border-box",
        }}
      >
        <h1 className="title">Products</h1>
        <Dropdown />
       
      </div>
      <hr />
      <ul className="product-list">
      {cars.map((e) => {
            
            return (
              <li className="item" key={uuidv4()}>
                <Card
                  img={"images/car.png"}
                  name={e.make}
                  model={e.model}
                  price={e.price}
                  key={uuidv4()}
                  year={e.year}
                />
              </li>
            );
          })}
      </ul>
    </div>
  );
}

// filer dropdown
function Dropdown() {
  return (
    <div className="dropdown">
      <button
        className="btn dropdown-toggle"
        type="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          fill="currentColor"
          className="bi bi-sliders"
          viewBox="0 0 16 16"
        >
          <path
            fill-rule="evenodd"
            d="M11.5 2a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3M9.05 3a2.5 2.5 0 0 1 4.9 0H16v1h-2.05a2.5 2.5 0 0 1-4.9 0H0V3zM4.5 7a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3M2.05 8a2.5 2.5 0 0 1 4.9 0H16v1H6.95a2.5 2.5 0 0 1-4.9 0H0V8zm9.45 4a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3m-2.45 1a2.5 2.5 0 0 1 4.9 0H16v1h-2.05a2.5 2.5 0 0 1-4.9 0H0v-1z"
          />
        </svg>
      </button>
      <ul className="dropdown-menu">
        <li>
          <a className="dropdown-item" href="#">
            Tesla
          </a>
        </li>
        <li>
          <a className="dropdown-item" href="#">
            Toyota
          </a>
        </li>
        <li>
          <a className="dropdown-item" href="#">
            Volvo
          </a>
        </li>
      </ul>
    </div>
  );
}
