import Car from "../../models/car.ts";
import axios from "axios";
import { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
export default function Home() {
  const [cars, setCars] = useState([]);
  useEffect(() => {
    axios.get("/cars.json").then((res) => {
      setCars(res.data);
    });
  }, []);

  return (
    <main className="App-main">
      <div className="highlight-video">
        <div className="video-container">
          <h1>Search silently and fast</h1>
          <video muted autoPlay loop playsInline>
            <source src="prev.mp4" />
          </video>
        </div>
      </div>
      <div className="brand-list">
        <h1 className="title" style={{ textAlign: "center" }}>
          Brands
        </h1>
        <ul className="scrollable list">
          <Item link={"/#"} img={"images/rand.jpg"} name={"Tesla"} />
          <Item link={"/#"} img={"images/b6.jpg"} name={"BYD"} />
          <Item link={"/#"} img={"images/aaa.png"} name={"NIO"} />
          <Item link={"/#"} img={"images/car.png"} name={"Lucid"} />
          <Item link={"/#"} img={"images/rand.jpg"} name={"Rivian"} />
          <Item link={"/#"} img={"images/rand.jpg"} name={"Xpeng"} />
          <Item link={"/#"} img={"images/rand.jpg"} name={"Polestar"} />
          <Item link={"/#"} img={"images/rand.jpg"} name={"Faraday Future"} />
        </ul>
        <button
          id="prevBtn"
          onClick={(e) => {
            e.target.parentElement
              .querySelector("ul")
              .scrollBy({ left: -200, behavior: "smooth" });
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            fill="currentColor"
            className="bi bi-arrow-left-square-fill"
            viewBox="0 0 16 16"
            style={{ pointerEvents: "none" }}
          >
            <path d="M16 14a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2zm-4.5-6.5H5.707l2.147-2.146a.5.5 0 1 0-.708-.708l-3 3a.5.5 0 0 0 0 .708l3 3a.5.5 0 0 0 .708-.708L5.707 8.5H11.5a.5.5 0 0 0 0-1" />
          </svg>
        </button>
        <button
          id="nextBtn"
          onClick={(e) => {
            e.target.parentElement
              .querySelector("ul")
              .scrollBy({ left: +200, behavior: "smooth" });
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            fill="currentColor"
            className="bi bi-arrow-right-square-fill"
            viewBox="0 0 16 16"
            style={{ pointerEvents: "none" }}
          >
            <path d="M0 14a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2a2 2 0 0 0-2 2zm4.5-6.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5a.5.5 0 0 1 0-1" />
          </svg>
        </button>
      </div>
      <div className="latest-product-list">
        <h1 className="title">Latest Automobiles</h1>
        <ul className="product-list">
          {cars.map((e) => {
            
            return (
              <li className="item" key={uuidv4()}>
                <Card
                  img={"images/car.png"}
                  name={e.make}
                  model={e.model}
                  price={e.price}
                  key={uuidv4()}
                  year={e.year}
                />
              </li>
            );
          })}
        </ul>
      </div>
    </main>
  );
}
function Item({ link, img, name }) {
  return (
    <li className="item">
      <a href={link}>
        <img src={img} alt="brand" />
        <p style={{ margin: 0 }}>{name}</p>
      </a>
    </li>
  );
}

export function Card({ img, name, price, id, model,year }) {
  return (
    <a href={`product/${id}`}>
      <div className="card">
        <img src={img} className="card-img-top" alt="..." />
        <div className="card-body">
          <h3>{model} <span>{year}</span></h3>

          <p className="card-text">{name}</p>
          <button href={"product/" + id} className="btn btn-secondary see-more-link">
            Details
          </button>
        </div>
      </div>
    </a>
  );
}
