export function Header() {
  return (
    <header className="App-header">
      <div className="App-logo">
        <a href="/">
          <img
            src="https://amantes30.github.io/icons/PNG/logo.png"
            className="App-logo"
            alt="logo"
          />
        </a>
      </div>
      <ul className="navlinks">
        <li>
          <a href="/about">
            <p>About us</p>
          </a>
        </li>
        <li>
          <a href="/products">
            <p>Electric cars</p>
          </a>
        </li>
        <li>
          <a href="/contact">
            <p>Nearby Store</p>
          </a>
        </li>
        <li>
          <a href="/contact">
            <p>Contact</p>
          </a>
        </li>
      </ul>
      <button id="menu-btn" onClick={openSideMenu}>
        Menu
      </button>

      <button id="call-btn">Call</button>
    </header>
  );
}
function openSideMenu() {
    document.querySelector(".side-menu").classList.toggle("active", true);
  }
