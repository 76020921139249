import { Outlet } from "react-router-dom";
import { Header } from "./Components/Header";
import { SideMenu } from "./Components/SideMenu";
import { Footer } from "./Components/Footer";
import { PhoneNav } from "./Components/PhoneNav";

export default function Layout() {
  return (
    <>
      <Header />
      <Outlet />
      <Footer />
      <PhoneNav />
      <SideMenu />
    </>
  );
}
