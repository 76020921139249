/*
These are the nacigation button at the bottom that only appear on phone screen sizes.
 */

export function PhoneNav() {
  return (
    <div className="sticky-tab-links">
      <ul>
        <li>
          <a href="https://wa.me/8618551778718">
            <img src="/images/whatsapp.svg" />
            <p>WhatsApp</p>
          </a>
        </li>
        <li>
          <a href="/">
            <img src="/images/wechat.svg"></img>
            <p>WeChat</p>
          </a>
        </li>
        <li>
          <a href="/">
            <img src="/images/arrow-up.svg"></img>
          </a>
        </li>
        <li>
          <a href="mailto:amantes30@yahoo.com">
            <img src="https://amantes30.github.io/icons/SVG/mail.svg"></img>
            <p>E-Mail</p>
          </a>
        </li>
        <li>
          <a href="/inquiry">
            <img src="/images/message.svg"></img>
            <p>Inquiry</p>
          </a>
        </li>
      </ul>
    </div>
  );
}
