function InquiryPage() {
  const btnStyle = {
    borderRadius: "5px",
    width: "120px",
  };
  const label = {
    display: "block",
  };
  const input = {
    width: "100%",
    marginBottom: "20px",
  };
  return (
    <main>
      <form
        style={{
          width: "95%",
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          maxWidth: "600px",
          flexDirection: "column",
        }}
      >
        <fieldset>
          <legend>Inquiry:</legend>
          <p>Our sales representative will contact you within 24 hours</p>
          <label style={label} for="name">
            Name{" "}
          </label>
          <input style={input} type="text" id="name" name="name" required></input>

          <br></br>
          <label style={label} for="email">
            Email{" "}
          </label>
          <input style={input} type="email" id="email" name="email" required></input>
          <br></br>
          <label style={label} for="content">
            Inquiry{" "}
          </label>
          <textarea
            rows={5}
            style={input}
            type="text"
            id="content"
            name="content"
            required
          ></textarea>
        </fieldset>
        {/* <input style={{display:"inline-block"}} type="text"></input>
      <input style={{display:"inline-block"}}></input>
      <textarea style={{display:"inline-block"}}></textarea> */}
        <button style={btnStyle}>Submit</button>
      </form>
    </main>
  );
}

export default InquiryPage;
