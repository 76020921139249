export function SideMenu() {
  return (
    <div className="side-menu">
      <ul className="nav-links">
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div className="App-logo">
            <a href="/">
              <img
                src="https://amantes30.github.io/icons/PNG/logo.png"
                className="App-logo"
                alt="logo"
              />
            </a>
          </div>
          <button
            title="close"
            type="button"
            id="close-menu-btn"
            onClick={closeSideMenu}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-x-lg"
              viewBox="0 0 16 16"
            >
              <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
            </svg>
          </button>
        </div>
        <hr />

        <li>
          <a href="/products">
            <p>Electric cars</p>
          </a>
        </li>

        <li>
          <a href="/contact">
            <p>Nearby Store</p>
          </a>
        </li>
        <li>
          <a href="/contact">
            <p>Contact</p>
          </a>
        </li>
        <li>
          <a href="/about">
            <p>About us</p>
          </a>
        </li>
      </ul>
    </div>
  );
}
function closeSideMenu() {
  console.log("close");
  document.querySelector(".side-menu").classList.toggle("active", false);
}
