import "./App.css";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import Layout from "./Layout";
import About from "./pages/about";
import Home from "./pages/Home/Home";
import Product from "./pages/Product/Product";
import ProductList from "./pages/productlist/ProductList";
import InquiryPage from "./pages/inquiry";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="about" element={<About />} />
          <Route path="products" element={<ProductList />} />
          <Route path="product/:id" element={<Product />} />
          <Route path="inquiry" element={<InquiryPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
